import React from 'react';
import styles from './component.module.scss';
import or from "../../../assets/coupe_or.png";
import argent from "../../../assets/coupe_argent.png";
import bounty from "../../../assets/bounty.png";
import cible from "../../../assets/cible.png";
import lunette from "../../../assets/lunette.png";
import sandwich from "../../../assets/sandwich.png";

export function ClassementComponent() { 
const rank = [
    {pseudo: "AsDePique",    ms:[53,43,52,79,55,60,79,0,45],kill:12,main: 2,side: 0, killer: 0, retrait: 0},
    {pseudo: "AngkorVan",    ms:[50,45,48,49,50,34,36,6,0],kill:5,main: 0,side: 0, killer: 0, retrait: 0},
    {pseudo: "AsGeoff",      ms:[55,5,61,11,21,17,54,29,42],kill:13,main: 0,side: 0, killer:0, retrait: 5},
    {pseudo: "Axelos",       ms:[0,3,0,56,14,70,70,4,52],kill:11,main: 0,side: 0, killer:0, retrait: 0},
    {pseudo: "Barbie",       ms:[55,42,45,32,49,63,29,52,35],kill:12,main: 0,side: 1, killer:0, retrait: 29},
    {pseudo: "BlackJack",    ms:[0,4,88,51,28,37,14,74,2],kill:17,main: 1,side: 0, killer:0, retrait: 0},
    {pseudo: "Boubou",      ms:[35,28,8,0,18,0,0,0,0],kill:12,main: 0,side: 0, killer:0, retrait: 0},
    {pseudo: "Bedeve",      ms:[5,36,18,67,9,47,69,55,10],kill:11,main: 0,side: 0, killer:0, retrait: 5},
    {pseudo: "BB",          ms:[63,42,23,14,11,82,5,19,48],kill:14,main: 0,side: 0, killer:0, retrait: 5},
    {pseudo: "Chouchen",     ms:[33,14,32,53,52,29,38,2,0],kill:5,main: 0,side: 0, killer:0, retrait: 0},
    {pseudo: "Cracott",     ms:[2,50,36,27,36,16,16,9,20],kill:1,main: 0,side: 0, killer:0, retrait: 2},
    {pseudo: "Davidou",     ms:[54,48,46,57,36,57,42,66,34],kill:4,main: 0,side: 0, killer:0, retrait: 34},
    {pseudo: "Drake",       ms:[27,67,9,33,51,78,51,29,43],kill:35,main: 0,side: 1, killer:1, retrait: 9},
    {pseudo: "Greg",       ms:[0,4,15,0,12,43,0,61,43],kill:3,main: 0,side: 0, killer:0, retrait: 0},
    {pseudo: "Kaelmy",      ms:[12,2,0,0,11,20,42,16,48],kill:2,main: 0,side: 0, killer:0, retrait: 0},
    {pseudo: "Ke20",        ms:[63,41,63,45,6,76,14,18,0],kill:13,main: 0,side: 0, killer:0, retrait: 0},
    {pseudo: "Lauroc",      ms:[83,49,44,39,82,43,23,51,58],kill:18,main: 2,side: 0, killer:0, retrait: 23},
    {pseudo: "Limace",      ms:[51,17,19,46,36,43,36,20,24],kill:19,main: 0,side: 1, killer:0, retrait: 17},
    {pseudo: "LSD",         ms:[12,54,24,17,0,15,18,46,25],kill:17,main: 0,side: 1, killer:0, retrait: 0},
    {pseudo: "Luckyluck",   ms:[38,41,12,13,0,49,35,33,22],kill:7,main: 0,side: 0, killer:0, retrait: 0},
    {pseudo: "Lycan",       ms:[0,17,36,0,60,54,0,58,0],kill:8,main: 0,side: 0, killer:0, retrait: 0},
    {pseudo: "Memed",       ms:[14,52,40,42,35,51,31,58,29],kill:6,main: 0,side: 0, killer:0, retrait: 14},
    {pseudo: "Mimi",        ms:[16,11,68,53,66,90,56,48,7],kill:8,main: 1,side: 0, killer:0, retrait: 7},
    {pseudo: "Milik",       ms:[29,59,63,29,52,59,53,39,49],kill:11,main: 0,side: 1, killer:0, retrait: 29},
    {pseudo: "Nanar",       ms:[0,0,6,5,8,6,9,23,17],kill:3,main: 0,side: 0, killer:0, retrait: 0},
    {pseudo: "Nuts",        ms:[24,55,25,69,25,39,20,42,36],kill:12,main: 0,side: 1, killer: 0, retrait: 20},
    {pseudo: "Pan28100",     ms:[51,56,50,17,63,26,68,0,0],kill:10,main: 0,side: 1, killer:0, retrait: 0},
    {pseudo: "Pancake",     ms:[6,22,6,14,27,10,0,0,0],kill:0,main: 0,side: 0, killer:0, retrait: 0},
    {pseudo: "Pat",       ms:[47,73,24,18,24,20,41,41,75],kill:9,main: 1,side: 0, killer:0, retrait: 18},
    {pseudo: "PtitLoup",   ms:[32,8,23,80,19,61,35,43,27],kill:20,main: 0,side: 0, killer:0, retrait: 8},
    {pseudo: "Storm",       ms:[36,37,56,45,12,67,49,48,40],kill:7,main: 0,side: 0, killer:0, retrait: 12},
    {pseudo: "Toto",      ms:[21,46,73,67,44,2,59,83,56],kill:28,main: 1,side: 1, killer:0, retrait: 2},
    {pseudo: "Urbella",     ms:[70,81,40,11,46,61,23,59,75],kill:29,main: 1,side: 1, killer:0, retrait: 11},
    {pseudo: "Vulcain",     ms:[21,36,14,55,47,52,26,26,13],kill:5,main: 0,side: 0, killer:0, retrait: 13},
    ]

    rank.sort((a,b) => (b.ms.reduce((sum, p) => sum + p) - b.retrait) - (a.ms.reduce((sum, p) => sum + p) - a.retrait));
    
    let thRanking: any[] = [];
    for (let i = 0; i < 9; i++) {
        thRanking.push(<th className={styles.thRanking}>M {i+1}</th>);
    }

    return (
        <>
        <h1 className={styles.title1}>Classement</h1>
        
        <div className={styles.legendSandwich}>
          <h3>Sandwich <img src={sandwich} alt="sandwich" width={20}/></h3>
          <p>Main: <span>BB</span></p>
          <p>Side: <span>BB</span></p>
        </div>
        <div className={styles.legendSandwich}>
          <h3>Bounty <img src={bounty} alt="bounty" width={20}/></h3>
          <p>Main: <span>Pat</span></p>
          <p>Side: <span>Drake</span></p>
        </div>
        <div className={styles.containerRanking}>
          <div className={styles.colFixed}>
            <table className={styles.tableRanking}>
              <thead className={styles.theadRanking}>
                <tr className={styles.trtheadRanking}>
                  <th className={styles.thRanking}>#</th>
                  <th className={styles.thRanking}>Pseudo</th>
                  <th className={styles.thRanking}>Victoire</th>
                  <th className={styles.thRanking}>Total</th>
                  <th className={styles.thRanking}>Total retenu</th>
                  <th className={styles.thRanking}>Kill</th>
                </tr>
              </thead>
              <tbody className={styles.tbodyRanking}>
                {rank.map((r) => (
                  <tr className={styles.trtbodyRanking}>
                    <td className={styles.tdRanking}>{rank.indexOf(r) + 1}</td>
                    <td className={styles.tdRanking + styles.left}>{r.pseudo}</td>
                    <td className={styles.tdRanking}>{r.killer ? <img src={cible}/> :''}{winner(r.main,or,r.side,argent)}</td>
                    <td className={styles.tdRanking}>{r.ms.reduce((sum, p) => sum + p)}</td>
                    <td className={styles.tdRanking}>{r.ms.reduce((sum, p) => sum + p) - r.retrait}</td>
                    <td className={styles.tdRanking}>{r.kill}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={styles.colOverflow}>
            <table className={styles.tableRanking}>
            <thead className={styles.theadRanking}>
                <tr className={styles.trtheadRanking}>
                    {thRanking}
                </tr>
            </thead>
              <tbody className={styles.tbodyRanking}>
                {rank.map((r) => (
                  console.log(r.ms),
                  <tr className={styles.trtbodyRanking}>
                    {r.ms && r.ms.map((m) => (
                        <td className={styles.tdRanking}>{m}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>   
        <div className={styles.legend}>
          <p><img src={or} alt='Main' title='Main' width={15} /> Winner Main</p>
          <p><img src={argent} alt='Side' title='Side' width={15} /> Winner Side</p>
          <p><img src={cible} alt='Killer' title='Killer' width={20} /> Best Killer</p>
        </div>
        <div className={styles.legendPoint}>
          <p>Main 2 Points par rang gagné, Side 1pts</p>
          <p>1 points par kill, bounty 2pts</p>
          <p>Bonification Main: +20pts, +14pts, +10pts, +8pts et du 5ème au 8ème +6pts</p>
          <p>Bonification Side: +7pts, +4pts, +2pts, +1pts</p>  
        </div>
        </>
    );
}

function winner(main: number,or: any, side: number, argent: any): any {
    let coupe: any[]= [];
    for (let i = 0; i < main; i++) {
        coupe.push(<img src={or} alt='winner' title='Main +5000' width={15} />);
    }

    for (let i = 0; i < side; i++) {
      coupe.push(<img src={argent} alt='winner' title='Side +2500' width={15} />);
    }
    return coupe;
  }